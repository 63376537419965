@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 10.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 10.2%;

    --muted: 222.2 20% 90%; /* Muted color derived from the primary */
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 10.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;

    --plyr-color-main: linear-gradient(to top, #8e00d6, #af52de);
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 10.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 210 20% 20%; /* Muted color for dark mode derived from the primary */
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  .max-container {
    @apply mx-auto max-w-[1440px];
  }
  .padding-container {
    @apply px-4 md:px-8 lg:px-16;
  }
  .tiptap {
    @apply dark:text-white;
  }
  .tiptap ul li::before {
    @apply dark:text-white;
  }
}

@layer components {
  .no-spinner {
    -moz-appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .bg-counter {
    background-image: url("../public/images/bg-counter.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@layer components {
  .bg-upload-video {
    background-image: url("../public/images/bg-upvideo.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@layer components {
}

/* filepond stylesheet */

/* filepond */

/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  border: none !important;
  cursor: pointer;
}

/* the text color of the drop label*/

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #0400ff;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: rgb(78, 78, 78);
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */

/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: green;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #969696;
}

/* Basic editor styles */
.tiptap:first-child {
  margin-top: 0;
}

/* List styles */
.tiptap ul {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
  list-style-type: none; /* Remove default bullets */
}

.tiptap ul li {
  position: relative;
  padding-left: 1.5rem; /* Space for the custom bullet */
}

.tiptap ul li::before {
  content: "•"; /* Unicode bullet character */
  position: absolute;
  left: 0;
  color: black; /* Bullet color */
  font-size: 1rem; /* Bullet size */
  line-height: 1; /* Align with text */
}

.tiptap ol {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
}

.tiptap ol li {
  list-style-position: inside; /* Ensures the numbers are inside the padding */
  color: black; /* Number color */
}

.tiptap ol li::marker {
  color: black; /* Number color */
}
.tiptap h1 {
  font-size: 2.25rem; /* text-4xl */
  font-weight: 700; /* font-bold */
  line-height: 2.5rem; /* line-height 4xl */
}

.tiptap h2 {
  font-size: 1.875rem; /* text-3xl */
  font-weight: 700; /* font-bold */
  line-height: 2.25rem; /* line-height 3xl */
}

.tiptap h3 {
  font-size: 1.5rem; /* text-2xl */
  font-weight: 700; /* font-bold */
  line-height: 2rem; /* line-height 2xl */
}

.tiptap h4 {
  font-size: 1.25rem; /* text-xl */
  font-weight: 700; /* font-bold */
  line-height: 1.75rem; /* line-height xl */
}

.tiptap h5 {
  font-size: 1.125rem; /* text-lg */
  font-weight: 700; /* font-bold */
  line-height: 1.75rem; /* line-height lg */
}

.tiptap h6 {
  font-size: 1rem; /* text-base */
  font-weight: 700; /* font-bold */
  line-height: 1.5rem; /* line-height base */
}

/* Code and preformatted text styles */
.tiptap code {
  background-color: #c4b5fd;
  border-radius: 0.4rem;
  color: #272727;
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
}

.tiptap pre {
  background: #1e1b4b;
  border-radius: 0.5rem;
  color: #1e1b4b;
  font-family: "JetBrainsMono", monospace;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.tiptap blockquote {
  border-left: 3px solid #7c3aed;
  margin: 1.5rem 0;
  padding-left: 1rem;
  background: #ede9fe;
  color: #7e22ce;
}

.tiptap hr {
  border: none;
  border-top: 1px solid #cbd5e1;
  margin: 2rem 0;
}

/* Basic editor styles */
.tiptap:first-child {
  margin-top: 0;
}

/* Link styles */
.tiptap a {
  color: #7e22ce;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.tiptap a:hover {
  color: #51018a;
}

@layer utilities {
  /* =====  SHADCN OVERRIDES */
  .shad-primary-btn {
    @apply bg-green-500 text-white !important;
  }

  .shad-danger-btn {
    @apply bg-red-700 text-white !important;
  }

  .shad-gray-btn {
    @apply cursor-pointer border  bg-background text-white !important;
  }

  .shad-input-label {
    @apply text-14-medium text-dark-700 !important;
  }

  .shad-input {
    @apply h-10  bg-background placeholder:text-slate-500 focus-visible:ring-0 focus-visible:ring-offset-0 !important;
  }

  .shad-input-icon {
    @apply h-10 border bg-background placeholder:text-slate-500 focus-visible:ring-0 focus-visible:ring-offset-0 !important;
  }

  .shad-textArea {
    @apply border bg-background placeholder:text-slate-500 focus-visible:ring-0 focus-visible:ring-offset-0 !important;
  }

  .shad-combobox-item {
    @apply data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50 !important;
  }

  .shad-combobox-trigger {
    @apply h-10 !important;
  }

  .shad-select-trigger {
    @apply h-10  border bg-background placeholder:text-slate-500 focus:ring-0 focus:ring-offset-0 !important;
  }

  .shad-select-content {
    @apply border bg-background !important;
  }

  .shad-dialog {
    @apply border bg-background !important;
  }

  .shad-dialog button {
    @apply focus:ring-0 focus:ring-offset-0 focus-visible:border-none focus-visible:outline-none focus-visible:ring-transparent focus-visible:ring-offset-0 !important;
  }

  .shad-error {
    @apply text-red-400 !important;
  }

  .shad-table {
    @apply overflow-hidden rounded-lg !important;
  }

  .shad-table-row-header {
    @apply text-light-200 border-b border-background hover:bg-transparent !important;
  }

  .shad-table-row {
    @apply text-light-200 border-b border-background !important;
  }

  .shad-otp {
    @apply flex w-full justify-between !important;
  }

  .shad-otp-slot {
    @apply text-36-bold  flex size-16 justify-center gap-4 rounded-lg border !important;
  }

  .shad-alert-dialog {
    @apply space-y-5 border bg-background outline-none !important;
  }

  .shad-sheet-content button {
    @apply top-2 focus:ring-0 focus:ring-offset-0 focus-visible:border-none focus-visible:outline-none focus-visible:ring-transparent focus-visible:ring-offset-0 !important;
  }
}
